import * as THREE from 'three';

let scene, camera, renderer, particles, particleSystem;
let mouseX = 0, mouseY = 0;
const particleCount = 2500; // Densité des particules
const originalPositions = new Float32Array(particleCount * 3); // Stocker les positions d'origine
let animationId; // Stocker l'ID de l'animation
let canvas; // Stocker une référence au canvas

const minLineSpacing = 25; // Espacement minimal entre les lignes
const maxLineSpacing = 75; // Espacement maximal entre les lignes
const minParticleSpacing = 10; // Espacement minimal entre les particules d'un groupe
const maxParticleSpacing = 30; // Espacement maximal entre les particules d'un groupe
const minSize = 1; // Taille minimale des particules
const maxSize = 4; // Taille maximale des particules
const minSpeed = 0.15; // Vitesse minimale des particules
const maxSpeed = 0.4; // Vitesse maximale des particules
const minOpacitySpeed = 0.01; // Vitesse minimale d'augmentation de l'opacité
const maxOpacitySpeed = 0.045; // Vitesse maximale d'augmentation de l'opacité

let positions = new Float32Array(particleCount * 3);
let sizes = new Float32Array(particleCount);
let opacities = new Float32Array(particleCount);
let speeds = new Float32Array(particleCount);
let opacitySpeeds = new Float32Array(particleCount);
let asciiIndices = new Float32Array(particleCount);

// const asciiChars = [
//     '@', '#', '$', '%', '&', '*', '+', '=', '?',
//     'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
//     'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
//     '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
//     '!', '@', '#', '$', '%', '&', '(', ')', '_', '+', '-', '=', '[', ']', '{', '}', '|', ';', ':', '<', '>', '/', '?',
// ];

const asciiChars = [
    '0',
];

function createAsciiTexture() {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const size = 128;
    canvas.width = size;
    canvas.height = size;
    context.fillStyle = 'white';
    context.font = `${size}px monospace`;
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(asciiChars[Math.floor(Math.random() * asciiChars.length)], size / 2, size / 2);
    return new THREE.CanvasTexture(canvas);
}

const materials = asciiChars.map(char => new THREE.PointsMaterial({
    size: 11,
    sizeAttenuation: true,
    map: createAsciiTexture(char),
    transparent: true,
    alphaTest: 0.5,
}));

export function init() {
    // Créer la scène
    scene = new THREE.Scene();

    // Créer la caméra
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
    camera.position.z = 500;

    // Créer le renderer
    renderer = new THREE.WebGLRenderer({ alpha: true }); // Permettre la transparence
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.domElement.style.position = 'fixed';
    renderer.domElement.style.top = '0';
    renderer.domElement.style.left = '0';
    renderer.domElement.style.zIndex = '-1'; // Positionner derrière le contenu
    document.body.appendChild(renderer.domElement);
    canvas = renderer.domElement; // Stocker une référence au canvas

    // Créer les particules
    particles = new THREE.BufferGeometry();

    let currentX = -window.innerHeight / 2; // Commencer en bas de l'écran
    let particleIndex = 0;

    while (currentX < window.innerHeight / 2 && particleIndex < particleCount) {
        const lineSpacing = Math.random() * (maxLineSpacing - minLineSpacing) + minLineSpacing;
        const lineSize = Math.random() * (maxSize - minSize) + minSize;
        const lineOpacity = Math.random() * 1 + 0.1;
        const lineSpeed = Math.random() * (maxSpeed - minSpeed) + minSpeed; // Vitesse aléatoire pour chaque ligne
        const lineOpacitySpeed = Math.random() * (maxOpacitySpeed - minOpacitySpeed) + minOpacitySpeed; // Vitesse d'augmentation de l'opacité pour chaque ligne

        let y = -window.innerWidth / 2;
        while (y < window.innerWidth / 2 && particleIndex < particleCount) {
            const groupSpacing = Math.random() * (maxParticleSpacing - minParticleSpacing) + minParticleSpacing;
            const groupSize = Math.floor(Math.random() * 5) + 1; // Taille du groupe de particules

            for (let i = 0; i < groupSize && y < window.innerWidth / 2; i++) {
                if (particleIndex >= particleCount) break;

                positions[particleIndex * 3] = currentX;
                positions[particleIndex * 3 + 1] = y;
                positions[particleIndex * 3 + 2] = 0;

                originalPositions[particleIndex * 3] = currentX;
                originalPositions[particleIndex * 3 + 1] = y;
                originalPositions[particleIndex * 3 + 2] = 0;

                sizes[particleIndex] = lineSize;
                opacities[particleIndex] = lineOpacity;
                speeds[particleIndex] = lineSpeed; // Vitesse pour chaque particule de la ligne
                opacitySpeeds[particleIndex] = lineOpacitySpeed; // Vitesse d'augmentation de l'opacité pour chaque particule de la ligne
                asciiIndices[particleIndex] = Math.floor(Math.random() * asciiChars.length); // Index aléatoire pour chaque particule

                particleIndex++;
                y += Math.random() * (maxParticleSpacing - minParticleSpacing) + minParticleSpacing; // Espacement aléatoire entre les particules du groupe
            }
            y += groupSpacing; // Espacement entre les groupes
        }
        currentX += lineSpacing;
    }

    particles.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    particles.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
    particles.setAttribute('opacity', new THREE.BufferAttribute(opacities, 1));
    particles.setAttribute('asciiIndex', new THREE.BufferAttribute(asciiIndices, 1));

    particleSystem = new THREE.Points(particles, materials[0]);
    scene.add(particleSystem);

    // Ajouter les écouteurs d'événements
    if (window.matchMedia("(max-width: 768px)").matches) {
        document.addEventListener('touchmove', onDocumentTouchMove, false);
    } else {
        document.addEventListener('mousemove', onDocumentMouseMove, false);
    }
    window.addEventListener('resize', onWindowResize, false);

    animate();
}

function onDocumentTouchMove(event) {
    mouseX = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
    mouseY = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
}

function onDocumentMouseMove(event) {
    mouseX = (event.clientX / window.innerWidth) * 2 - 1;
    mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
}

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    resizeParticles();
}

function resizeParticles() {
    let currentX = -window.innerHeight / 2; // Commencer en bas de l'écran
    let particleIndex = 0;

    while (currentX < window.innerHeight / 2 && particleIndex < particleCount) {
        const lineSpacing = Math.random() * (maxLineSpacing - minLineSpacing) + minLineSpacing;
        const lineSize = Math.random() * (maxSize - minSize) + minSize;
        const lineOpacity = Math.random() * 1 + 0.1;
        const lineSpeed = Math.random() * (maxSpeed - minSpeed) + minSpeed; // Vitesse aléatoire pour chaque ligne
        const lineOpacitySpeed = Math.random() * (maxOpacitySpeed - minOpacitySpeed) + minOpacitySpeed; // Vitesse d'augmentation de l'opacité pour chaque ligne

        let y = -window.innerWidth / 2;
        while (y < window.innerWidth / 2 && particleIndex < particleCount) {
            const groupSpacing = Math.random() * (maxParticleSpacing - minParticleSpacing) + minParticleSpacing;
            const groupSize = Math.floor(Math.random() * 5) + 1; // Taille du groupe de particules

            for (let i = 0; i < groupSize && y < window.innerWidth / 2; i++) {
                if (particleIndex >= particleCount) break;

                positions[particleIndex * 3] = currentX;
                positions[particleIndex * 3 + 1] = y;
                positions[particleIndex * 3 + 2] = 0;

                originalPositions[particleIndex * 3] = currentX;
                originalPositions[particleIndex * 3 + 1] = y;
                originalPositions[particleIndex * 3 + 2] = 0;

                sizes[particleIndex] = lineSize;
                opacities[particleIndex] = lineOpacity; // Opacité pour chaque particule de la ligne
                speeds[particleIndex] = lineSpeed; // Vitesse pour chaque particule de la ligne
                opacitySpeeds[particleIndex] = lineOpacitySpeed; // Vitesse d'augmentation de l'opacité pour chaque particule de la ligne
                asciiIndices[particleIndex] = Math.floor(Math.random() * asciiChars.length); // Index aléatoire pour chaque particule

                particleIndex++;
                y += Math.random() * (maxParticleSpacing - minParticleSpacing) + minParticleSpacing; // Espacement aléatoire entre les particules du groupe
            }
            y += groupSpacing; // Espacement entre les groupes
        }
        currentX += lineSpacing;
    }
    particles.attributes.position.needsUpdate = true;
    particles.attributes.opacity.needsUpdate = true;
}

function distance(x1, y1, x2, y2) {
    return Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2));
}

function animate() {
    animationId = requestAnimationFrame(animate);

    // Faire bouger les particules en fonction de la position de la souris
    for (let i = 0; i < particleCount; i++) {
        const dx = (positions[i * 3] - mouseX * window.innerWidth / 2);
        const dy = (positions[i * 3 + 1] - mouseY * window.innerHeight / 2);
        const dist = distance(positions[i * 3], positions[i * 3 + 1], mouseX * window.innerWidth / 2, mouseY * window.innerHeight / 2);

        if (dist < 60) {
            positions[i * 3] += dx / dist * 1; // Augmenter la force de l'écartement
            positions[i * 3 + 1] += ((dy / dist * 1) - (speeds[i])); // Augmenter la force de l'écartement
        } else {
            // Ramener les particules à leur position d'origine
            originalPositions[i * 3 + 1] -= speeds[i];
            positions[i * 3] += (originalPositions[i * 3] - positions[i * 3]) * 0.02;
            positions[i * 3 + 1] += (originalPositions[i * 3 + 1] - positions[i * 3 + 1]) * 0.02;
        }

        // Ajouter un léger mouvement vers le bas
        positions[i * 3 + 1] -= speeds[i];
        if (positions[i * 3 + 1] < -window.innerHeight / 2) {
            positions[i * 3 + 1] = window.innerHeight / 2;
            originalPositions[i * 3 + 1] = window.innerHeight / 2;
        }

        // Augmenter l'opacité jusqu'à atteindre l'opacité désirée
        if (opacities[i] < 1) {
            opacities[i] += opacitySpeeds[i];
            if (opacities[i] > 1) {
                opacities[i] = 1;
            }
        }

        // faire en sorte que les particules se repoussent entre elles

        if (Math.random() < 0.) { // Change le matériau avec une probabilité de 1% à chaque frame
            particleSystem.material = materials[Math.floor(Math.random() * materials.length)];
            particles.material = materials[Math.floor(Math.random() * materials.length)];
        }
    }

    particles.attributes.position.needsUpdate = true;
    particles.attributes.opacity.needsUpdate = true;

    renderer.render(scene, camera);
}

export function cleanup() {
    // Annuler l'animation
    if (animationId) {
        cancelAnimationFrame(animationId);
    }
    // Supprimer les particules de la scène
    if (scene && particleSystem) {
        scene.remove(particleSystem);
    }
    // Détruire la géométrie et le matériau des particules
    if (particles) {
        particles.dispose();
    }
    if (particleSystem && particleSystem.material) {
        particleSystem.material.dispose();
    }
    // Détruire le renderer
    if (renderer) {
        renderer.dispose();
    }
    // Supprimer le canvas du DOM
    if (canvas && canvas.parentNode) {
        canvas.parentNode.removeChild(canvas);
    }
    // Détacher les écouteurs d'événements
    document.removeEventListener('mousemove', onDocumentMouseMove, false);
    window.removeEventListener('resize', onWindowResize, false);
}