import { init as initBackgroundEffect } from './backgroundEffect.js';
import { injectHeaderFooter } from './injectHeaderFooter.js';
import { alternateLetters } from './text.js';


document.addEventListener('DOMContentLoaded', () => {
    injectHeaderFooter();

    const headerElements = document.querySelectorAll('header h1 a, header nav a');
    headerElements.forEach(element => {
        alternateLetters(element);
    });

    const currentPath = window.location.pathname;
    if (currentPath === '/' || currentPath === '/index.html') {
        import('./initApp.js').then(module => {
            const initializeHomePage = module.initializeHomePage;
            initializeHomePage();
        });
    } else {
        initBackgroundEffect();
    }

    // Fonction pour gérer les changements de mode
    function handleModeChange(e) {
        if (e.matches) {
            // Mode mobile
            console.log('Mode mobile activé');
            // Ajoutez ici le code spécifique au mode mobile
        } else {
            // Mode bureau
            console.log('Mode bureau activé');
            // Ajoutez ici le code spécifique au mode bureau
        }
    }

    // Créer un MediaQueryList pour observer les changements de mode
    const mediaQueryList = window.matchMedia('(max-width: 768px)');

    // Ajouter un écouteur pour les changements de media queries
    mediaQueryList.addListener(handleModeChange);

    // Appeler la fonction une fois pour définir l'état initial
    handleModeChange(mediaQueryList);    
});