export function injectHeaderFooter() {
    // const oldHeader = document.querySelector('header');
    // const oldFooter = document.querySelector('footer');
    // if (oldHeader) {
    //     oldHeader.remove();
    // }
    // if (oldFooter) {
    //     oldFooter.remove();
    // }
    
    const headerHTML = `
        <header>
            <h1><a href="/" id="home-link">To The Apogee</a></h1>
            <nav>
                <ul>
                    <li><a href="/news" id="news-link">News</a></li>
                    <li><a href="/about" id="about-link">About</a></li>
                    <li><a href="/contact" id="contact-link">Contact</a></li>
                </ul>
            </nav>
        </header>
    `;

    const footerHTML = `
        <footer>
            <p>© 2024 To The Apogee, Inc.</p>
            <nav>
                <ul>
                    <li><a href="/terms" id="terms-link">Terms</a></li>
                    <li><a href="/privacy" id="privacy-link-footer">Privacy</a></li>
                    <li><a href="/contact" id="contact-link-footer">Contact</a></li>
                </ul>
            </nav>
        </footer>
    `;

    const noscriptElement = document.querySelector('noscript');
    if (noscriptElement) {
        noscriptElement.insertAdjacentHTML('afterend', headerHTML);
    } else {
        document.body.insertAdjacentHTML('afterbegin', headerHTML);
    }
    document.body.insertAdjacentHTML('beforeend', footerHTML);
}